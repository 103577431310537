import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const SplashScreen = () => {
  return (
    <div className="splash-cont">
      <ClipLoader
        color={'var(--text-primary)'}
        loading={true}
        size={300}
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={15 / 45}
      />
      <img
        src={require('../../src/assets/images/logo.png')}
        className="logo"
        alt=""
      ></img>
    </div>
  )
}

export default SplashScreen
