import React, { useEffect } from 'react'
import clsx from 'clsx'

export default function HeaderWhite(props) {
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)')
    mediaQuery.addListener(handleMediaQueryChange)
    handleMediaQueryChange(mediaQuery)

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, [])

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      // setIsSmallScreen(true)
    } else {
      // setIsSmallScreen(false)
    }
  }

  return (
    <header className={clsx('Header')}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderTop: '.1rem solid #e4baa1',
        }}
      >
        <div className="Logo">
          <a href="#main">
            <img
              src={require('../assets/images/logoWhite.png')}
              alt="logo"
              className="img1"
            />
          </a>
        </div>

        <div className="nav-items">
          <a href="#about-us">About Us</a>
          <a href="#portfolio">Portfolio</a>
          <a href="#service">Service</a>
        </div>
      </div>
    </header>
  )
}
