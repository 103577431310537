import React from 'react'

const Services = () => {
  return (
    <div className="services-cont">
      <div className="header-cont">
        <span className="main-head items-highlighter">Services</span>
      </div>
      <div className="right-cont">
        <div className="contents">
          <div className="content content-left">
            <span
              className="text-1"
              style={{
                width: '100%',
                textAlign: 'center',
                fontFamily: 'Aboreto',
                fontSize: '2rem',
              }}
            >
              We Undertake Turnkey Projects & Consultations
            </span>
          </div>
          <div className="content">
            <span className="text-1">Digital Survey</span>
            <span className="text-2" style={{ color: '#e4baa1' }}>
              Soil Testing
            </span>
          </div>
          <div className="content content-left">
            <span className="text-1" style={{ color: '#e4baa1' }}>
              Planning
            </span>
            <span className="text-2">Structural Design</span>
          </div>
          <div className="content">
            <span className="text-1">VDF Flooring</span>
            <span className="text-2" style={{ color: '#e4baa1' }}>
              Electrical & IT Solutions
            </span>
            <span className="text-2">Interior</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
