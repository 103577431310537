export const PortfolioData = [
  {
    id: 1,
    image1Portrait: require('../../assets/images/Gal1-1Portrait.jpg'),
    image1Landscape: require('../../assets/images/Gal1-2Landscape.png'),
    image2Portrait: require('../../assets/images/_DSC5630.jpeg'),
    image2Landscape: require('../../assets/images/_DSC5638.jpeg'),
    mainHead1: 'Jhansi Kia, Davangere',
    content: [
      'Welcome to the prestigious Jhansi Kia Showroom project in Davanagere! As a pioneering name in the Pre-Engineered Building (PEB) industry, we are thrilled to embark on this exciting endeavor.',
      "Our team of skilled engineers and designers is dedicated to crafting a state-of-the-art facility that will exude elegance and innovation, perfectly reflecting Kia's brand image",
      'From conceptualization to execution, we ensure the highest standards of quality, incorporating cutting-edge technology and sustainable practices. ',
      "Together, let's build a showroom that will leave a lasting impression and redefine automotive experiences.",
    ],
    content2: [
      'At Jhansi Kia, we believe in creating spaces that inspire and engage. With a focus on efficiency and functionality, we aim to optimize every aspect of the PEB project to cater to the specific needs of the automotive industry.',
      ' Our commitment to delivering projects on time and within budget is unwavering, and we take pride in our customer-centric approach.',
      "As we set out to construct the Kia Showroom in Davanagere, we envision a design that captures the essence of Kia's vision while ensuring a seamless and enjoyable customer journey.",
      'Get ready to witness a dynamic space that elevates the automotive retail experience to new heights.',
    ],
  },
  {
    id: 2,
    image1Portrait: require('../../assets/images/_DSC5820.jpeg'),
    image1Landscape: require('../../assets/images/_DSC5809.jpeg'),
    image2Portrait: require('../../assets/images/_DSC5814.jpeg'),
    image2Landscape: require('../../assets/images/_DSC5823.jpeg'),
    mainHead1: 'Maruthi Suzuki Nexa , Davangere',
    content: [
      'Welcome to the future of automotive elegance and innovation with the Maruti Suzuki Nexa showroom in Davanagere. Our PEB project stands as a testament to avant-garde design and engineering, redefining the automotive retail experience.',
      'At Maruti Suzuki Nexa, we pride ourselves on crafting a space where luxury and technology seamlessly intertwine.',
      "As you step into our showroom, you'll be greeted by a fusion of sleek aesthetics and state-of-the-art Pre-Engineered Building techniques, reflecting the core values of the Nexa brand.",
    ],
    content2: [
      'Driven by a commitment to excellence, Maruti Suzuki Nexa in Davanagere sets new benchmarks in architectural prowess and customer-centricity',
      'With an unwavering focus on sustainability and aesthetics, our PEB project reimagines automotive retail as a holistic experience',
      'Discover a captivating blend of form and function, where design brilliance meets the latest offerings from Maruti Suzuki.',
      'Embark on a journey that transcends ordinary showrooms, where every detail echoes our passion for automobiles and dedication to our valued patrons.',
    ],
  },
  {
    id: 3,
    image1Portrait: require('../../assets/images/_DSC5608.jpeg'),
    image1Landscape: require('../../assets/images/Gal3.jpeg'),
    image2Portrait: require('../../assets/images/_DSC5598.jpeg'),
    image2Landscape: require('../../assets/images/_DSC5604.jpeg'),
    mainHead1: 'Shodha Toyota , Davangere',
    content: [
      'Step into a world of automotive excellence at Toyota showroom in Davanagere, where innovative design and exceptional service converge',
      "Our PEB project, Shodha Toyota, stands as a testament to Toyota's legacy of quality, reliability, and cutting-edge technology. ",
      "From its striking architectural facade to its meticulously crafted interiors, every detail of our showroom exudes the essence of Toyota's brand ethos.",
      "As you explore our showroom, you'll experience a seamless blend of modern aesthetics and a commitment to customer satisfaction that truly sets us apart.",
    ],
    content2: [
      'At Shodha Toyota in Davanagere, we believe in delivering more than just vehicles; we deliver experiences that inspire and endure.',
      "Our Pre-Engineered Building project embodies Toyota's unwavering dedication to excellence, reflecting a space where automotive enthusiasts and discerning customers alike can explore the latest Toyota models with confidence and excitement.",
      "With a focus on innovation and sustainability, our showroom redefines automotive retail, creating a harmonious atmosphere that resonates with Toyota's vision of mobility for a better future.",
      'Discover the true essence of Toyota at our showroom, where every visit promises a journey of discovery and satisfaction.',
    ],
  },
  {
    id: 4,
    image1Portrait: require('../../assets/images/_DSC5708.JPG'),
    image1Landscape: require('../../assets/images/TrueValue.jpeg'),
    image2Portrait: require('../../assets/images/_DSC5713.jpeg'),
    image2Landscape: require('../../assets/images/_DSC5697.jpeg'),
    mainHead1: 'True Value',
    content: [
      'Welcome to the True Value Showroom in Davanagere, a pinnacle of excellence in the world of pre-owned cars.',
      "Our PEB project, aptly named 'True Value,' is a harmonious fusion of modern architecture and automotive expertise. ",
      "As you step into our showroom, you'll be greeted by a symphony of quality, reliability, and innovation, all meticulously curated to provide you with the finest selection of pre-owned vehicles.",
      'At True Value, we redefine the pre-owned car buying experience, ensuring that each customer embarks on a journey of trust, transparency, and utmost satisfaction.',
    ],
    content2: [
      'Discover a realm of unmatched value and trust at True Value in Davanagere. Our Pre-Engineered Building project sets the stage for a unique car buying experience, where every vehicle narrates its own story of quality and authenticity.',
      "With a commitment to delivering not just cars but lasting memories, True Value is more than just a showroom; it's a promise of premium pre-owned vehicles, backed by a team of experts who are passionate about making your car ownership dreams a reality.",
      "Embrace the road ahead with confidence and pride, as you drive home in a True Value-certified car that's as unique as you are.",
    ],
  },
  {
    id: 5,
    image1Portrait: require('../../assets/images/RoyalOak1.png'),
    image1Landscape: require('../../assets/images/RoyalOak2.png'),
    image2Portrait: require('../../assets/images/RoyalOak3.png'),
    image2Landscape: require('../../assets/images/RoyalOak4.png'),
    mainHead1: 'Royal Oak',
    content: [
      'Welcome to the world of timeless elegance and exquisite craftsmanship at Royaloak inDavanagere.',
      ' Our PEB project, aptly named Royaloak, is a celebration of fine living, offering a stunning range of meticulously crafted furniture that transforms houses into homes.',
      "As you explore our showroom, you'll be captivated by a symphony of design, quality, and comfort, all thoughtfully curated to elevate your living spaces.",
      " At Royaloak, we pride ourselves on being more than just a furniture store; we're your partners in creating spaces that reflect your personality and lifestyle.",
    ],
    content2: [
      'Step into a realm of sophistication and aesthetic appeal at Royaloak in Davanagere. Our Pre-Engineered Building project embodies the essence of contemporary living, showcasing an extensive collection of furniture that marries artistry with functionality.',
      'With a commitment to delivering not just furniture but a statement of elegance, our showroom offers a diverse range of options that cater to every taste and preference',
      'Discover the perfect blend of style and comfort as you embark on a journey of transforming your living spaces into a true reflection of your individuality. At Royaloak, we invite you to embrace the art of living beautifully.',
    ],
  },
  {
    id: 6,
    image2Portrait: require('../../assets/images/_DSC5663.jpeg'),
    image2Landscape: require('../../assets/images/_DSC5665.jpeg'),
    image1Portrait: require('../../assets/images/_DSC5655.jpeg'),
    image1Landscape: require('../../assets/images/_DSC5653.jpeg'),
    mainHead1: 'Maruthi Suzuki Arena',
    content: [
      'Step into a world of automotive excellence at Maruti Suzuki Arena in Davanagere, where cutting-edge design meets unparalleled customer experience',
      'Our PEB project, Maruti Suzuki Arena, stands as a true embodiment of innovation and style, redefining the way you explore and engage with vehicles.',
      'With a perfect blend of form and function, our showroom showcases the latest offerings from Maruti Suzuki, surrounded by an architectural marvel that exemplifies modern engineering and aesthetics.',
    ],
    content2: [
      "Maruti Suzuki Arena in Davanagere is not just a showroom; it's an invitation to discover the thrill of driving in an environment that mirrors our commitment to quality and distinction.",
      " Every corner of our Pre-Engineered Building project reflects the essence of Maruti Suzuki's brand values, emphasizing customer-centricity, transparency, and technological prowess.",
      "Experience a seamless journey of exploration, where the world of Maruti Suzuki comes to life in a space that's designed to inspire and elevate your car buying journey.",
    ],
  },
  {
    id: 7,
    image1Portrait: require('../../assets/images/_DSC5730.jpeg'),
    image1Landscape: require('../../assets/images/_DSC5732.jpeg'),
    image2Portrait: require('../../assets/images/_DSC5733.jpeg'),
    image2Landscape: require('../../assets/images/_DSC5729.jpeg'),
    mainHead1: 'Ather',
    content: [
      'Welcome to the future of electric mobility at Ather showroom in Davanagere, where cutting-edge design meets sustainable innovation.',
      "Our PEB project, aptly named Ather, is a testament to the brand's commitment to redefining urban transportation.",
      "As you step into our showroom, you'll be immersed in a world of sleek aesthetics and advanced technology, reflecting Ather's vision of electric vehicles that are not just eco-friendly, but also high-performing and stylish.",
      'At Ather, we invite you to explore the future of mobility, powered by innovation and driven by a passion for a greener tomorrow.',
    ],
    content2: [
      "Experience the thrill of emission-free mobility at Ather in Davanagere. Our Pre-Engineered Building project is a harmonious blend of form and function, providing a captivating space where enthusiasts and environmentally-conscious individuals can discover Ather's range of electric scooters.",
      "With a focus on sustainability and a commitment to revolutionize urban commuting, our showroom offers a unique opportunity to witness the evolution of transportation firsthand. Whether you're an urban dweller or a forward-thinker, Ather is your gateway to embracing a cleaner, smarter, and more exhilarating way of getting around. ",
      "Join us in shaping a future where every ride counts, and together, let's drive change for a better world.",
    ],
  },
  {
    id: 8,
    image1Portrait: require('../../assets/images/_DSC5791.jpeg'),
    image1Landscape: require('../../assets/images/_DSC5793.jpeg'),
    image2Portrait: require('../../assets/images/_DSC5801.jpeg'),
    image2Landscape: require('../../assets/images/_DSC5796.jpeg'),
    mainHead1: 'Raithara Mane',
    content: [
      'Welcome to the heart of agricultural prosperity at Raithara Mane showroom in Davanagere, your destination for premium farming equipment and solutions.',
      'Our PEB project, proudly named Raithara Mane, stands as a tribute to the tireless efforts of farmers and their invaluable contribution to our society. ',
      " As you step into our showroom, you'll be greeted by a comprehensive range of cutting-edge farming tools and machinery, meticulously curated to enhance productivity and simplify agricultural practices.",
      'At Raithara Mane, we are not just a store; we are your partners in cultivating success and empowering the farming community',
    ],
    content2: [
      'Experience a revolution in farming at Raithara Mane in Davanagere. Our Pre-EngineeredBuilding project is a culmination of innovation and tradition,',
      "offering a diverse array of high-quality equipment that caters to the modern needs of farmers. With a deep-rooted commitment to agricultural progress, our showroom provides a space where farmers can access the latest advancements in farming technology, backed by expert guidance and exceptional service. Whether you're a seasoned agriculturist or a newcomer to the field,",
      'Raithara Mane is your one-stop destination to access the tools you need for a bountiful harvest. Join us in cultivating a brighter future for farming, one innovative solution at a time',
    ],
  },
  {
    id: 9,
    image1Portrait: require('../../assets/images/_DSC5781.jpeg'),
    image1Landscape: require('../../assets/images/_DSC5763.jpeg'),
    image2Portrait: require('../../assets/images/_DSC5775.jpeg'),
    image2Landscape: require('../../assets/images/_DSC5770.jpeg'),
    mainHead1: 'Tata Motors',
    content: [
      'Welcome to Adishakthi Tata Showroom in Davanagere, where automotive passion meets architectural innovation',
      "Our PEB project stands as a shining example of Tata's commitment to excellence, blending modern design with cutting-edge technology. As you step into our showroom,",
      "you'll be greeted by a symphony of Tata's finest vehicles, displayed in a space that embodies the brand's legacy of performance, reliability, and innovation.",
      "At Adishakthi Tata, we invite you to explore a world of automotive marvels, where every vehicle tells a story of Tata's relentless pursuit of excellence.",
    ],
    content2: [
      "Adishakthi Tata in Davanagere isn't just a showroom; it's a testament to Tata's rich heritage and future-forward vision. Our Pre-Engineered Building project is a harmonious blend of aesthetics and functionality, providing a seamless environment for customers to engage with Tata's diverse lineup of vehicles.",
      'With a focus on customer satisfaction and sustainable design, our showroom is a space where automotive enthusiasts and families alike can embark on a journey of discovery.',
      "Whether you're seeking the perfect Tata vehicle or simply exploring automotive innovation, Adishakthi Tata is here to provide an exceptional experience that reflects Tata's legacy of excellence.",
    ],
  },
  {
    id: 10,
    image1Portrait: require('../../assets/images/shahi5.jpeg'),
    image1Landscape: require('../../assets/images/shahi1.jpeg'),
    image2Portrait: require('../../assets/images/shahi3.jpeg'),
    image2Landscape: require('../../assets/images/shahi4.jpeg'),
    mainHead1: 'SHAHI EXPORTS, BYADGI.',
    content: [
      'At SR Associates, we take immense pride in our role as the builders of tomorrow, and the Shahi Exports factory in Byadgi stands as a testament to our commitment to excellence.',
      'As a trusted construction company, we were honored to be entrusted with the construction of the Pre-Engineered Building (PEB) project for Shahi Exports, a renowned manufacturer of clothing.',
      'Our collaboration with Shahi Exports showcases our dedication to precision, innovation, and sustainability in the construction industry. Together, we have crafted a cutting-edge facility that not only symbolizes quality but also underscores our expertise in creating spaces that redefine industries.',
    ],
    content2: [
      'The Shahi Exports factory in Byadgi, a result of our partnership with SR Associates,exemplifies the synergy between visionary design and meticulous construction.',
      "Our PEB project is more than just a building; it's a testament to the potential of collaboration and the power of purposeful construction. This facility is not just where clothing is manufactured; it's where ideas come to life, where innovation thrives, and where the future of fashion is shaped.",
      'At SR Associates, we are committed to delivering construction solutions that stand the test of time, and our work with Shahi Exports is a shining example of our dedication to building a better tomorrow, one project at a time.',
    ],
  },
]
