import React from 'react'

const Portfolio = () => {
  const handleClick = (url) => {
    window.location.href = url
  }

  return (
    <div className="portfolio-main-cont">
      <div className="header-cont">
        <span className="completd">Our completed projects</span>
        <span className="main-head">Portfolio</span>
        <span className="link" onClick={() => handleClick('/portfolio/1')}>
          View all
        </span>
      </div>
      <div className="portfolio-cont">
        <div className="left-cont">
          <div
            onClick={() => handleClick('/portfolio/1')}
            className="image-cont"
          >
            <img src={require('../../assets/images/Gal1.jpeg')} alt="" />
          </div>
          <div className="captions">Jhansi KIA Showroom</div>
          <div className="captions-gray">
            PB Rd, opp. GMIT COLLEGE, Davanagere, Karnataka 577006
          </div>
        </div>

        <div className="right-cont">
          <div className="cont">
            <div
              onClick={() => handleClick('/portfolio/2')}
              className="image-cont"
            >
              <img src={require('../../assets/images/Nexa.png')} alt="" />
            </div>
            <div className="captions">Maruthi Suzuki Nexa</div>
            <div className="captions-gray">
              Door No.323/1a, Old, PB Rd, Avaragere, Davanagere, Karnataka
              577003
            </div>
          </div>

          <div className="cont">
            <div
              onClick={() => handleClick('/portfolio/3')}
              className="image-cont"
            >
              <img src={require('../../assets/images/Gal3.jpeg')} alt="" />
            </div>
            <div className="captions">Shodha Toyota</div>
            <div className="captions-gray">
              Davangere - Harihar Rd, Vinobha Nagar, Davanagere, Karnataka
              577006
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio-cont" style={{ flexDirection: 'row-reverse' }}>
        <div className="left-cont">
          <div
            onClick={() => handleClick('/portfolio/6')}
            className="image-cont"
          >
            <img src={require('../../assets/images/_DSC5656.JPG')} alt="" />
          </div>
          <div className="captions">Maruthi Suzuki Arena</div>
          <div className="captions-gray">
            PB Rd, near Panch Devasthana, Davanagere, Karnataka 577006
          </div>
        </div>

        <div className="right-cont">
          <div className="cont">
            <div
              onClick={() => handleClick('/portfolio/4')}
              className="image-cont"
            >
              <img src={require('../../assets/images/TrueValue.jpeg')} alt="" />
            </div>
            <div className="captions">True Value</div>
            <div className="captions-gray">
              FVFV+3G4, Davanagere, Karnataka 577004
            </div>
          </div>

          <div className="cont">
            <div
              onClick={() => handleClick('/portfolio/5')}
              className="image-cont"
            >
              <img src={require('../../assets/images/RoyalOak.png')} alt="" />
            </div>
            <div className="captions">Royal Oak</div>
            <div className="captions-gray">
              Door no:871/3A Poona Banglore Road, Davanagere, Karnataka 577006
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio-cont">
        <div className="left-cont">
          <div
            onClick={() => handleClick('/portfolio/8')}
            className="image-cont"
          >
            <img src={require('../../assets/images/_DSC5785.JPG')} alt="" />
          </div>
          <div className="captions">Raithara Mane</div>
          <div className="captions-gray">
            #354, Opp: Reliance Petrol Bunk, P B Road,, Davangere, India,
            Karnataka
          </div>
        </div>

        <div className="right-cont">
          <div className="cont">
            <div
              onClick={() => handleClick('/portfolio/7')}
              className="image-cont"
            >
              <img src={require('../../assets/images/Ather.png')} alt="" />
            </div>
            <div className="captions">Ather</div>
            <div className="captions-gray">
              Beside Hyundai Showroom, PB Road, Bhairidevarkoppa, Hubli,
              Karnataka, Pin 580025
            </div>
          </div>

          <div className="cont">
            <div
              onClick={() => handleClick('/portfolio/9')}
              className="image-cont"
            >
              <img src={require('../../assets/images/_DSC5763.JPG')} alt="" />
            </div>
            <div className="captions">Tata Motors</div>
            <div className="captions-gray">
              No 628/K24, Shramajeevi Building, PB Road, beside Aradhya Steel
              Wire Ropes, Avaragere, Davanagere, Karnataka 577003
            </div>
          </div>
        </div>
      </div>
      <div
        className="portfolio-cont"
        style={{ flexDirection: 'row-reverse', justifyContent: 'center' }}
      >
        <div className="left-cont">
          <div
            onClick={() => handleClick('/portfolio/10')}
            className="image-cont"
          >
            <img src={require('../../assets/images/shahi5.jpeg')} alt="" />
          </div>
          <div className="captions">Shahi Exports, Byadgi.</div>
          <div className="captions-gray">
            MFCF+CH9, Byadgi Rural, Karnataka 581106
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portfolio
