import React, { useEffect } from 'react'
import HeaderWhite from '../../components/HeaderWhite'
import { PortfolioData } from './PortfolioData'
import { useParams } from 'react-router'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom'

const PortfolioScreen = () => {
  const { id } = useParams()
  let selectedIndex = Number(id)
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [id])

  return (
    <>
      <HeaderWhite />
      <div className="main-portfolio-cont">
        <div className="prev-next-btn-cont">
          <div className="btn-cont">
            <span
              onClick={() => {
                if (selectedIndex !== 1) {
                  navigate(`/portfolio/${selectedIndex - 1}`)
                }
              }}
            >
              Prev
            </span>
            <span
              onClick={() => {
                if (selectedIndex !== PortfolioData.length) {
                  navigate(`/portfolio/${selectedIndex + 1}`)
                }
              }}
            >
              Next
            </span>
          </div>
        </div>

        {PortfolioData?.map(
          (d, index) =>
            index + 1 === selectedIndex && (
              <>
                <div className="content">
                  <div className="top-cont">
                    <div className="left-cont">
                      <div className="main-head">{d.mainHead1}</div>

                      {d.content.map((ds) => (
                        <div className="content">{ds}</div>
                      ))}
                    </div>
                    <div className="right-cont">
                      <img
                        src={d.image1Portrait}
                        alt=""
                        loading="lazy"
                        sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, 800px"
                      />
                    </div>
                  </div>

                  <div className="bottom-cont">
                    <img
                      src={d.image1Landscape}
                      alt=""
                      loading="lazy"
                      sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, 800px"
                    />
                  </div>
                </div>
                <div className="content" style={{ marginTop: '15rem' }}>
                  <div
                    className="top-cont"
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <div className="left-cont">
                      {d.content2.map((ds) => (
                        <div className="content">{ds}</div>
                      ))}
                    </div>
                    <div className="right-cont second-one">
                      <img
                        src={d.image2Portrait}
                        alt=""
                        style={{ marginLeft: '-7rem' }}
                        loading="lazy"
                        sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, 800px"
                      />
                    </div>
                  </div>

                  <div
                    className="bottom-cont"
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <img
                      src={d.image2Landscape}
                      alt=""
                      loading="lazy"
                      sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, 800px"
                    />
                  </div>
                </div>
              </>
            ),
        )}

        <Footer />
      </div>
    </>
  )
}

export default PortfolioScreen
