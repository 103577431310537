import React from 'react'

const Footer = () => {
  return (
    <div className="footer-cont" id="contact-us">
      <div className="first-cont">
        <div className="link-cont">
          <img
            src={require('../assets/images/logo.png')}
            alt="logo"
            className="img1"
          />
        </div>

        <div className="link-cont">
          <span className="link">
            "Sarvamangal", #831, 2nd Main, 6th Cross, Nijalingappa Layout,
            Davangere - 577004.
          </span>
        </div>

        <div className="link-cont">
          <span className="link">Landline - 08192-470624</span>
          <span className="link">Phone - +91 94483 75866</span>
          <span className="link">Email - letstalk@sraconstruct.com</span>
        </div>

        <div className="link-cont">
          <a href="#about-us" className="links">
            About Us
          </a>
          <a href="#portfolio" className="links">
            Portfolio
          </a>
          <a href="#service" className="links">
            Service
          </a>
        </div>

        <div className="link-cont">
          <span className="link">
            Designed By :{' '}
            <span style={{ color: '#c80f2f', fontWeight: 500 }}>Hextrem</span>{' '}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer
