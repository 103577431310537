import React from 'react'

const AboutUs = () => {
  return (
    <div className="about-us-cont">
      <div className="header-cont">
        <span className="completd">Our philosophy</span>
        <span className="main-head">About Us</span>
        <span className="link"></span>
      </div>

      <div className="portfolio-cont">
        <div className="left-cont">
          <div className="image-cont">
            <img src={require('../../assets/images/Gal4.jpeg')} alt="" />
          </div>
        </div>

        <div className="right-cont">
          <div className="cont">
            <span className="text">
              S.R. Associates is a highly experienced civil engineering company
              with over 35 years of expertise in the field. As dedicated civil
              engineers and contractors, we have successfully completed numerous
              projects, earning a reputation for excellence and reliability. Our
              primary focus is on commercial projects, turnkey solutions, and
              interior works.
            </span>
            <span className="text">
              With a strong portfolio, S.R. Associates has accomplished over 100
              projects involving Pre-Engineered Building (PEB) structures. Our
              technical proficiency, innovative approach, and meticulous
              attention to detail have earned the trust of clients across
              Karnataka.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
