import React from 'react'
import sample from '../../assets/video.mp4'
import VideoPlayer from 'react-background-video-player'
import Header from '../../components/Header'

const Banner = (props) => {
  return (
    <>
      <Header />
      <div className="banner-cont">
        <VideoPlayer
          className="video"
          src={sample}
          autoPlay={true}
          muted={true}
        />

        <div className="content-overlay">
          <div className="content-box">
            <span className="text">
              WE BUILD <span className="items-highlighter">TRUST</span>{' '}
            </span>

            <span className="slogan">
              We build stylish, energetic and high quality buildings.
            </span>

            <a href="#footer" className="contact-us">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner
