import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'animate.css/animate.min.css'
import SplashScreen from './components/SplashScreen'
import Navigations from './navigation/Navigations'
// import VideoPlayer from 'react-background-video-player'

function App() {
  const myRef = React.createRef()
  const [loadSplashScreen, setLoadSplashScreen] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoadSplashScreen(false)
    }, 1500)
  }, [])

  return (
    <div className="body--dark" style={{ height: '100%' }}>
      {loadSplashScreen ? (
        <SplashScreen />
      ) : (
        <BrowserRouter>
          <div className="app-routes" ref={myRef}>
            <Navigations />
          </div>
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
