import React, { useRef } from 'react'
import Footer from '../../components/Footer'
import Banner from './Banner'
import Portfolio from './Portfolio'
import AboutUs from './AboutUs'
import Services from './Services'

const Home = (props) => {
  const { playVideo } = props
  const contentRef = useRef()

  function handleBackClick() {
    contentRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <div className="home-cont" id="main">
      <Banner playVideo={playVideo} handleBackClick={handleBackClick} />
      <div className="home-contents-cont" ref={contentRef}>
        <div id="portfolio" />
        <Portfolio />
        <div id="about-us" />
        <AboutUs />
        <div id="service" />
        <Services />
      </div>
      <div id="footer" />
      <Footer />
    </div>
  )
}

export default Home
